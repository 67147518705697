<template>
	<div class="page-login">
		<div class="login-wrap">
			
			<div class="page-title">{{ $t('login.title') }}</div>
			
			<div class="form-wrap">
				<div class="form-item">
					<div class="form-floating">
						<input class="form-control" type="email" id="emailFloat" :placeholder="$t('email')" v-model="loginForm.email">
						<label for="emailFloat">{{ $t('email') }}</label>
					</div>
					<div v-if="v$.loginForm.email.$error && v$.loginForm.email.required.$invalid" class="invalid-feedback">
						{{ $t('validation.required') }}
					</div>
					<div v-if="v$.loginForm.email.$error && v$.loginForm.email.email.$invalid" class="invalid-feedback">
						{{ $t('validation.email') }}
					</div>
				</div>
				<div class="form-item">
					<div class="form-floating">
						<input class="form-control" type="password" id="passwordFloat" :placeholder="$t('password')" v-model="loginForm.password">
						<label for="passwordFloat">{{ $t('password') }}</label>
					</div>
					<div v-if="v$.loginForm.password.$error && v$.loginForm.password.required.$invalid" class="invalid-feedback">
						{{ $t('validation.required') }}
					</div>
					<div v-if="loginError" class="invalid-feedback">
						{{ $t('validation.badLogin') }}
					</div>
				</div>
				
			</div>
			
			<div class="forgot-password">
				<button type="button" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#forgotPasswordModal">{{ $t('login.forgotPassword') }}</button>
			</div>
			
			<div class="modal fade" id="forgotPasswordModal" tabindex="-1" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<div class="modal-title fs-5" id="forgotPasswordModalLabel">{{ $t('forgotPassword.title') }}</div>
							<button type="button" class="btn-close" data-bs-dismiss="modal" :aria-label="$t('cancel')"></button>
						</div>
						<div class="modal-body">
							<p>{{ $t('forgotPassword.text') }}</p>
							
							<div class="modal-form-wrap">
								
								<div class="form-item">
									<div class="form-floating">
										<input class="form-control" type="text" id="forgotPasswordEmailFloat" :placeholder="$t('email')" v-model="forgotPasswordForm.email">
										<label for="forgotPasswordEmailFloat">{{ $t('email') }}</label>
									</div>
									<div v-if="forgotPasswordSuccess" class="valid-feedback">{{ $t('forgotPassword.success') }}</div>
									<div v-if="!forgotPasswordSuccess && v$.forgotPasswordForm.email.$error && v$.forgotPasswordForm.email.required.$invalid" class="invalid-feedback">
										{{ $t('validation.required') }}
									</div>
									<div v-if="!forgotPasswordSuccess && v$.forgotPasswordForm.email.$error && v$.forgotPasswordForm.email.email.$invalid" class="invalid-feedback">
										{{ $t('validation.email') }}
									</div>
									<div v-if="forgotPasswordError" class="invalid-feedback">
										{{ $t('validation.serverError') }}
									</div>
								</div>
								
							</div>
							
							<div v-if="forgotPasswordLoading" class="loading spinner-border mt-2" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('cancel') }}</button>
							<button :disabled="forgotPasswordLoading" type="button" class="btn btn-primary-gradient" @click="submitForgotPassword">{{ $t('forgotPassword.submit') }}</button>
						</div>
					</div>
				</div>
			</div>
			
			<div v-if="loading" class="loading spinner-border" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
			
			<button :disabled="loading" type="submit" class="btn btn-primary-gradient btn-submit" @click="submitLogin">{{ $t('submitLogin') }}</button>
			<button type="button" class="btn btn-link btn-register" @click="toRegister">{{ $t('login.register') }}</button>
			
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
	setup() {
		return {
			v$: useVuelidate()
		}
	},
	data() {
		return {
			loginForm: {
				email: '',
				password: ''
			},
			
			forgotPasswordForm: {
				email: ''
			},
			
			loading: false,
			forgotPasswordLoading: false,
			
			loginError: false,
			forgotPasswordSuccess: false,
			forgotPasswordError: false,
		}
	},
	validations() {
		return {
			loginForm: {
				email: {
					required, email
				},
				password: {
					required
				}
			},
			
			forgotPasswordForm: {
				email: {
					required, email
				},
			}
		}
	},
	
	mounted() {
		this.initModalEvents()
	},
	
	methods: {
		submitLogin() {
			this.loginError = false
			
			this.v$.loginForm.$touch()
			if (!this.v$.loginForm.$error) {
				this.login()
			}
		},
		
		login() {
			this.loading = true
			
			const body = {
				email: this.loginForm.email,
				password: this.loginForm.password
			}
			
			this.$axios.post(`${this.$config.API_URL}/login`, body, this.$config.JS_HEADERS)
				.then((res) => {
					console.log(res)

					this.$store.dispatch('token', res.data.data.auth.access_token)
					this.$store.dispatch('user', res.data.data.user)
					
					this.$router.push({name: 'IdeaForm'})
				}, (err) => {
					console.log(err)
					this.loginError = true
				}).finally(() => {
					this.loading = false
				})
		},
		
		initModalEvents() {
			const forgotPasswordModal = document.querySelector('#forgotPasswordModal')
			forgotPasswordModal.addEventListener('hidden.bs.modal', () => {
				this.clearForgotPassword()
			})
		},
		
		submitForgotPassword() {
			this.v$.forgotPasswordForm.$touch()
			if (!this.v$.forgotPasswordForm.$error) {
				this.forgotPassword()
			}
		},
		
		forgotPassword() {
			this.forgotPasswordLoading = true
			
			this.forgotPasswordSuccess = false
			this.forgotPasswordError = false
			
			const body = {
				email: this.forgotPasswordForm.email,
				method: 3
			}
			
			this.$axios.post(`${this.$config.API_URL}/password/reset/send`, body, this.$config.JS_HEADERS)
				.then((res) => {
					console.log(res)
					this.forgotPasswordSuccess = true
					this.clearForgotPassword()
				}, (err) => {
					console.log(err)
					this.forgotPasswordError = true
				}).finally(() => {
					this.forgotPasswordLoading = false
				})
		},
		
		clearForgotPassword() {
			this.forgotPasswordForm.email = ''
		},
		
		toRegister() {
			this.$router.push({name: 'Register'})
		}
	}
}
</script>

<style lang="scss" scoped>
#zmen-form .page-login::v-deep(.login-wrap) {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: linear-gradient(to bottom, var(--zmen-form-background-gradient-light), var(--zmen-form-background-gradient-dark));
	
	.page-title {
		margin: 60px 0 100px;
		font-family: var(--zmen-form-font-family);
		font-size: 25px;
		font-weight: 600;
		color: white;
		text-align: center;
		text-transform: uppercase;
	}
	
	.form-wrap {
		margin-left: 40px;
		margin-right: 40px;
	}
	
	.forgot-password {
		margin-bottom: 40px;
		text-align: center;
		
		.btn-link {
			text-transform: uppercase;
			text-decoration: none;
			font-size: 13px;
			font-weight: 200;
			color: #46D3FF;
			
			&:hover {
				color: rgba(70, 211, 255, 0.7);
			}
		}
	}
	
	#forgotPasswordModal {
		.btn-secondary {
			margin-right: 10px;
		}
	}
	
	.btn-submit {
		align-self: center;
		width: calc(100% - 80px);
		max-width: 280px;
		margin: 20px 0 10px;
	}
	
	.btn-register {
		align-self: center;
		margin-bottom: 30px;
	}
}
</style>